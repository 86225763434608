// Llamada a Strapi
export const getStrapiData = async (url: string, query: any) => {
  const runtimeConfig = useRuntimeConfig()
  const { find } = useStrapi()
  const { locale } = useI18n()
  let content: any
  try {
    const data: any = await find(url, query)
    content = data?.data?.attributes
  } catch (error) {
    console.error('Error llamda Strapi', error)
    try {
      const { data: contentData } = await useFetch(`/strapi/${locale.value}/${url}`)
      content = extractStrapiData(contentData?.value?.[0])
    } catch (fetchError) {
      console.error('Error llamda de respaldo', fetchError)
      throw new Error('No se pudo obtener los datos de Strapi')
    }
  }
  return content
}

export const extractStrapiData = (response: any) => {
  return response?.data?.attributes
}